<template>
  <div>
    <div class="app-container monthMoneyInfo">
      <section class="titlebox" style="margin-top:20px">
        <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
        <!-- <div class="title">{{DataInfo.staffName}}成本详情</div> -->
        <div class="title">成本{{$route.query.type ? '编辑' : '详情'}}</div>
      </section>
      <section class="viewBox" v-loading="loading">
        <!-- <p><span>修改人：{{DataInfo.staffName}}</span> <span>员工编号：{{DataInfo.jobNumber}}</span></p> -->
        <el-table
          :data="DataInfo.payrollData"
          stripe
          :header-cell-style="tableHeaderColor"
          style="margin-top:20px"
        >
          <el-table-column
            v-for="(col,i) in columns"
            :prop="col.id"
            align="center"
            :key="col.id"
            :label="col.label"
            :width="col.width"
          >
            <template slot-scope="{row}">
              <el-input-number
                v-model="row[col.id]"
                v-if="change===true&&i>columns.length-3"
                :min="1"
                class="moneyinput"
                :precision="2"
                :controls="false"
              ></el-input-number>
              <span v-else>{{row[col.id]}}</span>
            </template>
          </el-table-column>
        </el-table>
        <p style="margin:20px 0">成本明细</p>
        <el-table
          :data="DataInfo.enterpriseCostRecoredsList"
          :header-cell-style="tableHeaderColor"
          stripe
        >
          <el-table-column prop="name" label="项目" align="center"></el-table-column>
          <el-table-column prop="money" label="金额" align="center">
            <template slot-scope="{row}">
              <el-input-number
                v-model="row.money"
                v-if="row.change"
                :precision="2"
                :controls="false"
                class="moneyinput"
              ></el-input-number>
              <span v-else>{{row.money}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            v-if="$route.query.type && !searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.change"
                @click="cancel(scope.row,scope.$index)"
              >取消</el-button>
              <el-button
                type="text"
                @click="changeInfopayroll(scope.row,scope.$index)"
              >{{scope.row.infoText}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <p  class="moryeInfoTitle">
          <strong>
            合计=
            <span
              v-for="(word,i) in DataInfo.enterpriseCostConfigTree"
              :key="i"
              style="margin-right:0"
            >{{i==0?'':'+'}}{{word.typeName}}</span>
          </strong>
        </p>
        <div v-for="(word,i) in DataInfo.enterpriseCostConfigTree" :key="i" class="text">
          <div :class="i == 0 ? 'moryeInfoText' : 'moryeInfoCri'">
            <div class="moryeInfoCriRound"></div>
            {{word.typeName}}＝
            <span
              v-for="(children,i1) in word.children"
              :key="i1"
            >{{i1==0?'':'+'}}{{children.typeName}}</span>
          </div>
          <!--<ul>
                    <li>月度价值贡献补贴实发＝月度价值贡献补贴基数×价值贡献率</li>
                    <li>税前补款＝补贴合计＋加班合计+奖金合计+差旅补贴（纳税）+其他补款（纳税</li>
                    <li>税前扣款＝其他扣款（纳税</li>
                    <li>社保公积金个人扣款＝养老个人扣款＋失业个人扣款＋医疗个人扣款＋公积金个人扣款</li>
                    <li>税后补款=差旅补贴（免税）＋其他补款（免税）</li>
                    <li>税后扣款＝爱心互助金＋冲借款＋住宿费＋资产遗失赔偿＋补充医疗扣款＋其他扣款（免税）等</li>
                    <li>差旅补贴＝差旅补贴（纳税）＋差旅补贴（免税）</li>
          </ul>!-->
        </div>
      </section>
      <section class="viewBox" style="text-align:center" v-loading="loading">
        <!-- <p>考勤明细</p>
            <el-table :data="kaoqin" :header-cell-style="tableHeaderColor" stripe>
                <el-table-column label="项目" prop="project" align="center"></el-table-column>
                <el-table-column label="时间（小时）" prop="time" align="center"></el-table-column>
                <el-table-column label="项目" prop="moneyproject" align="center"></el-table-column>
                <el-table-column label="金额" prop="money" align="center"></el-table-column>
        </el-table>-->
        <!-- <p style="margin-bottom:10px"><strong style="color:#444;font-size:14px">【工资清单说明】</strong></p>
            <ol>
                <li>月计薪天数说明：
                   <p>21.75天＝（365天－104天（休息日））÷12个月</p>
                </li>
                <li>月薪计算说明：
                    <p>1)当月入职＝试用期工资÷21.75×实际工作天数</p>
                    <p>2)当月转正＝转正后工资÷21.75×转正后工作天数+试用期工资÷21.75×(21.75－转正后工作天数)</p>
                    <p>3)当月离职＝当月全勤工资÷21.75×实际工作天数</p>
                    <div>4)当月在职且休假
                        <div>①当月全勤工资＝基本工资+岗位工资+绩效工资</div>
                        <div>②病假工资＝当月全勤工资÷21.75÷8×当月病假小时数*20%，若全月病假，当月实发工资不足当地最低工资的80%，按最低工资标准的80%补足</div>
                        <div>③事假工资＝当月全勤工资÷21.75÷8×当月事假小时数*0</div>
                        <div>④年休假/倒休工资＝当月全勤工资÷21.75÷8×当月年休假/倒休小时数*100%</div>
                        <div>⑤产检假/陪产假/哺乳假工资＝当月全勤工资÷21.75÷8×当月符合国家政策且符合公司考勤管理制度的休假小时数*100%</div>
                        <div>⑥丧事假工资＝当月全勤工资÷21.75÷8×当月符合公司考勤管理制度的休假小时数*100%</div>
                        <div>⑦迟到早退工资＝当月全勤工资÷21.75÷8×迟到早退小时数*0</div>
                        <div>⑧旷工工资＝当月全勤工资÷21.75÷8×旷工小时数*0</div>
                        <div>⑨欠工时工资＝当月全勤工资÷21.75÷8×欠工时小时数*0</div>
                    </div>
                </li>
        </ol>-->
        <el-button
          type="primary"
          :disabled="btnDisabled()"
          v-if="$route.query.type && !searchForm.isinfo&&(searchForm.isthisMonth||searchForm.grantType!='1')"
          @click="submitChange()"
        >提交</el-button>
      </section>
    </div>
  </div>
</template>

<script>
import { PrisecostInfo, PrisecostUpdate } from '../../../api/remuneration';
export default {
  data() {
    return {
      columns: [],
      DataInfo: {},
      searchForm: {},
      text: '修改',
      change: false,
      whichchange: [],
      loading: false,
      data: [],
      mingxi: [],
      kaoqin: []
    };
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #F0F8FF;';
      }
    },
    getDetail() {
      this.loading = true;
      PrisecostInfo({ month: this.$route.query.month }).then((res) => {
        if (res._responseStatusCode == 0) {
          const obj = {};

          this.columns = [];
          if (
            res.enterpriseCostConfigTree &&
            res.enterpriseCostConfigTree.length > 0 &&
            res.enterpriseCostTtatisticsRecoredsList &&
            res.enterpriseCostTtatisticsRecoredsList.length > 0
          ) {
            res.enterpriseCostTtatisticsRecoredsList.forEach((it) => {
              obj[`${it.name}${it.id}`] = it.money;
              this.columns.push({ id: `${it.name}${it.id}`, label: it.name });
            });
            //     res.paidValue=res.payrollList[res.payrollList.length-1].money
            //     res.payableValue=res.payrollList[res.payrollList.length-2].money
            res.payrollData = [obj];
            // }
            // if(this.searchForm.isthisMonth||this.searchForm.grantType!='1'){
            res.copypayrollData = JSON.parse(JSON.stringify([obj]));
            res.enterpriseCostRecoredsList.forEach((it) => {
              it.change = false;
              it.infoText = '修改';
            });
            res.copypayrollInfoData = JSON.parse(
              JSON.stringify(res.enterpriseCostRecoredsList)
            );
            // }
            this.DataInfo = res;
            this.loading = false;
            // })
          }
        }
      });
    },
    btnDisabled() {
      let status = false;

      if (!this.change) {
        this.DataInfo.enterpriseCostRecoredsList &&
          this.DataInfo.enterpriseCostRecoredsList.forEach((it) => {
            if (it.change == true) {
              status = true;
            }
          });
      } else {
        status = true;
      }
      return status;
    },
    cancel(row, index) {
      if (!row) {
        this.change = false;
        this.DataInfo.payrollData = this.DataInfo.copypayrollData;
        this.text = '修改';
      } else {
        this.DataInfo.enterpriseCostRecoredsList[index].change = false;
        this.DataInfo.enterpriseCostRecoredsList[
          index
        ].money = this.DataInfo.copypayrollInfoData[index].money;
        this.DataInfo.enterpriseCostRecoredsList[index].infoText = '修改';
      }
    },
    changepayroll() {
      if (!this.change) {
        this.change = true;
        this.text = '确定';
      } else {
        this.DataInfo.payrollList.map((it) => {
          for (const key in this.DataInfo.payrollData[0]) {
            if (it.randomCode == key) {
              it.money = this.DataInfo.payrollData[0][key];
            }
            if (it.name == '应发工资') {
              this.DataInfo.payableValue = it.money;
            } else if (it.name == '实发工资') {
              this.DataInfo.paidValue = it.money;
            }
          }
        });
        this.change = false;
        this.whichchange.push('1');
        this.text = '修改';
      }
    },
    changeInfopayroll(row, index) {
      if (!row.change) {
        this.DataInfo.enterpriseCostRecoredsList[index].change = true;
        this.DataInfo.enterpriseCostRecoredsList[index].infoText = '确定';
      } else {
        this.DataInfo.enterpriseCostRecoredsList[index].change = false;
        this.whichchange.push('2');
        this.DataInfo.enterpriseCostRecoredsList[index].infoText = '修改';
      }
    },
    submitChange() {
      this.$confirm(
        `是否确定修改${this.$route.query.month}的企业成本`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const params = {
          month: this.$route.query.month,
          payrollUpdateList: this.DataInfo.enterpriseCostRecoredsList
        };

        PrisecostUpdate(params).then((res) => {
          if (res.code == 0) {
            this.$message.success('成本详情修改成功');
            this.getDetail();
          }
        });
      });
    }
  },
  created() {
    if (this.$route.params.staffId) {
      this.searchForm = this.$route.params;
    } else if (
      !this.$route.params.staffId &&
      this.$route.name == 'currentInfo'
    ) {
      this.searchForm = JSON.parse(sessionStorage.getItem('remunerationInfo'));
    } else if (
      !this.$route.params.staffId &&
      this.$route.name == 'staffSalaryDetails'
    ) {
      this.searchForm = JSON.parse(
        sessionStorage.getItem('staffremunerationInfo')
      );
    }
    this.getDetail();
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  mounted() {}
};
</script>

<style lang="scss">
.monthMoneyInfo {
  padding: 0 30px;
  .moryeInfoTitle {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    font-weight: bold;
    color: #498df0;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
  }
  .moryeInfoText {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #2b3642;
    font-weight: bold;
    letter-spacing: -0.22px;
    margin-bottom: 8px;
  }
  .moryeInfoCri {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    color: #2b3642;
    letter-spacing: -0.22px;
    margin-bottom: 6px;
    .moryeInfoCriRound {
      display: inline-block;
      width: 9px;
      height: 9px;
      border: 1px solid #498df0;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .moryeInfoLast {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #2b3642;
    font-weight: normal;
    letter-spacing: -0.22px;
    padding-left: 16px;
    margin-bottom: 8px;
  }
  .viewBox {
    background: #fff;
    padding: 14px 40px;
    p span {
      margin-right: 20px;
    }
    > div,
    .text > div {
      margin-bottom: 8px;
    }
    ul > li {
      list-style: inside;
      font-size: 12px;
      line-height: 18px;
      padding-left: 10px;
    }
    ol > li {
      list-style: decimal inside;
      font-size: 13px;
      line-height: 18px;
      padding-left: 10px;
    }
    .moneyinput {
      width: 130px;
      .el-input__inner {
        text-align: center;
      }
    }
    > .el-button {
      margin: 20px 0 20px 10px;
    }
  }
}
</style>
